
.notebook-head:hover {
	background: #eef;
	cursor:pointer;
}


.no-break {
	flex-wrap: nowrap;
	white-space: nowrap;
}
