button, a {
	cursor: pointer !imporant;
}

.dream-header {
	border: 1px solid #ddf;
	border-bottom: none;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	background:#eee;
}

.dream-footer {
	border: 1px solid #ddf;
	border-top: none;
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;
}

.dream-body {
	background: #fdfdff;
}

.my-pre {
	display:block;
	font-family: monospace;
	white-space: pre-wrap;
	overflow-wrap : break-word;
}

.circle:hover {
	background:#eef;
	cursor:pointer;
}

.circle > .rt-Box:hover {
	background:#eef;
}

.notebook, .notebook:visited, .circle, .circle:visited {
	text-decoration: none;
	color: inherit;

}

.notebook-open {
	background: var(--plum-2);
}

.notebook-head:hover {
	background: #eef;
	cursor:pointer;
}

.notebook-cur {
	background: #ddf;
}

.page {
	min-height: 10rem;
}

/* --- */

.nb-full {
	min-width:95vw !important;
	min-height:90vh;
	background:red;
}
