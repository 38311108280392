.button-spinner {
  width: 16px; /* Adjust size as needed */
  height: 16px;
  border: 2px solid transparent; /* Hide initial border */
  border-top-color: currentColor; /* Top border inherits button's text color */
  border-radius: 50%;
  animation: spin 1s linear infinite; /* Animation for spinning */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg);
 }
}
