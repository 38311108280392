.category-head {
	background: var(--lime-3);
}

.no-break {
	flex-wrap: nowrap;
	white-space: nowrap;
}

.hato-sel {
	background: var(--plum-2);
}

.hato-head:hover {
	background: #eef;
	cursor:pointer;
}

.jhatricka {
	min-height:20rem;
}
