/* Raja */

@font-face {
	font-family: 'Virgil';
	src: url('./fonts/Virgil.woff2') format('woff2');
	font-weight: normal;
	font-style:normal;
}

.App {
}

/* other global styles... */

.radix-themes {  /* Add this snippet */
  --cursor-button: pointer;
  --cursor-checkbox: pointer;
  --cursor-disabled: default;
  --cursor-link: pointer;
  --cursor-menu-item: pointer;
  --cursor-radio: pointer;
  --cursor-slider-thumb: grab;
  --cursor-slider-thumb-active: grabbing;
  --cursor-switch: pointer;
}


.link-plain {
	text-decoration: none;
}
