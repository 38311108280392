.splash-text {
	text-align:center;
	position: absolute;
	width:100vw;
	margin: 2rem 0 0 0;
	z-index: 1;
	color:white;
}
.caption {
	font-family: Virgil;
	letter-spacing: 0.4rem;
}

.splash-text img {
	width: 10rem;
}

.defn {
	margin: 2rem auto;
	width:25rem;
	text-align:left;
}

.defn li {
	margin-top: 1rem;
}

button, a {
	cursor: pointer !imporant;
}
